import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { sendReferralStatics } from "../utils/api";
import "../styles/DownloadPage.css";

const DownloadPage = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Check if an ID parameter exists in the URL
        const id = searchParams.get("id");
        if (id) {
            // Save the ID to your API with source page info
            sendReferralStatics(id)
                .then(() => {
                    console.log("Referral ID saved successfully");
                })
                .catch((error) => {
                    console.error("Error saving referral ID:", error);
                });
        }

        // Handle redirection to app stores
        const ua = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(ua)) {
            window.location.href =
                "https://play.google.com/store/apps/details?id=com.nexumind.arkhass&appname=Rakhys";
        } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
            window.location.href =
                "https://apps.apple.com/sa/app/rakhys/id6587583728";
        }
    }, [searchParams]);

    return (
        <div className="download-page">
            <div className="download-container">
                <h1>Get the App</h1>
                <p>Redirecting you to the app store...</p>
                <p className="desktop-message">
                    {!/mobile|android|ios/i.test(navigator.userAgent) &&
                        "Please open this link on your mobile device to download the app."}
                </p>
            </div>
        </div>
    );
};

export default DownloadPage;
