import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { useTranslation } from "react-i18next";
import { useFilter } from "../../contexts/FilterContext";

const FilterSection = ({
    title,
    items,
    selectedItems,
    onChange,
    renderItem,
    isOpened = true,
    children,
    maxItems = 8,
    searchPlaceholder,
    enableSearch = false,
}) => {
    const [isOpen, setIsOpen] = useState(isOpened);
    const { state, dispatch } = useFilter();

    const idPrefix = title.substr(0, 3).toLocaleLowerCase();

    const toggleSection = () => {
        setIsOpen((prev) => !prev);
    };

    const { t } = useTranslation();

    const filteredItems = useMemo(() => {
        return items?.filter((item) =>
            item.name.toLowerCase().includes(state.searchBrand.toLowerCase()),
        );
    }, [items, state.searchBrand]);

    // use the filteredItems instead of items only if enableSearch is true
    const itemsToRender = enableSearch ? filteredItems : items;

    return (
        <div className="border-b-2 p-4 text-body-font">
            {/* title and arrow */}
            <div
                onClick={toggleSection}
                className="mb-3 flex cursor-pointer items-center justify-between"
            >
                <h3 className="font-heading text-lg font-semibold">{title}</h3>
                <svg
                    className={`h-5 w-5 transform transition-transform duration-300 ${
                        isOpen ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </div>

            {/* list item */}
            <div
                className={`transition-max-height overflow-hidden duration-500 ${
                    isOpen ? "max-h-72" : "max-h-0"
                }`}
                style={{
                    overflowY:
                        itemsToRender?.length > 0 &&
                        itemsToRender.length > maxItems
                            ? "scroll"
                            : "hidden",
                }}
            >
                {title === t("filter.price") && !items ? (
                    children
                ) : itemsToRender && itemsToRender.length > 0 ? (
                    <>
                        {/* Search input moved inside accordion and only shown if enableSearch is true and there are items */}
                        {enableSearch && (
                            <div className="relative mb-3">
                                <input
                                    type="text"
                                    placeholder={searchPlaceholder}
                                    value={state.searchBrand}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_SEARCH_BRAND",
                                            payload: e.target.value,
                                        })
                                    }
                                    className="w-full rounded border p-2 pr-8 focus:border-primary-orange focus:outline-none focus:ring-0"
                                />
                                {state.searchBrand && (
                                    <button
                                        onClick={() =>
                                            dispatch({
                                                type: "SET_SEARCH_BRAND",
                                                payload: "",
                                            })
                                        }
                                        className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        )}
                        {itemsToRender.map((item) =>
                            renderItem ? (
                                renderItem(
                                    item,
                                    selectedItems.includes(item.id),
                                    onChange,
                                )
                            ) : (
                                <div
                                    className="mb-3 flex items-center hover:text-primary-green"
                                    key={item.id}
                                >
                                    <input
                                        id={idPrefix + item.id}
                                        value={item.name}
                                        type="checkbox"
                                        checked={selectedItems.includes(item.id)}
                                        onChange={() => onChange(item.id)}
                                        className="h-4 w-4 cursor-pointer rounded border outline-none"
                                    />
                                    <label
                                        htmlFor={idPrefix + item.id}
                                        className="font-lato mx-2 cursor-pointer"
                                    >
                                        {capitalizeFirstLetter(item.name)}
                                    </label>
                                </div>
                            ),
                        )}
                    </>
                ) : (
                    <div className="text-center text-gray-500">
                        {t("filter.no_data_available", { title: title })}
                    </div>
                )}
            </div>
        </div>
    );
};

FilterSection.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
    selectedItems: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    renderItem: PropTypes.func,
    isOpened: PropTypes.bool,
    children: PropTypes.node,
    maxItems: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    enableSearch: PropTypes.bool,
};

export default FilterSection;
