import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import "../../../src/styles/chat.css";
import { sendMessage } from "../../utils/api.js";
import AIProductCard from "./ProductAICard.jsx";
import { useTranslation } from "react-i18next";
import aiChat from "../../assets/ai-chat.svg";
import aiLogoEn from "../../assets/ai-logo-en.svg";
import aiLogoAr from "../../assets/ai-logo-ar.svg";
import clear from "../../assets/clear-chat.svg";
import user from "../../assets/user.svg";
import close from "../../assets/close.svg";
import sendIcon from "../../assets/sendIcon.svg";
import stopIcon from "../../assets/stopIcon.svg";
import { createSlug } from "../../utils/stringUtils.js";

const FloatingChatButton = () => {
    const { t, i18n } = useTranslation();
    
    const language = i18n.resolvedLanguage;
    const TOOLTIP_STORAGE_KEY = "tooltipHideTime";
    const TOOLTIP_DISPLAY_DURATION = Date.now() + 24 * 60 * 60 * 1000;
    
    // ------------ user id ------------
    const [sessionId, setSessionId] = useState(null);
    const [randomHelperTextLabels, setRandomHelperTextLabels] = useState([]);
    const [isHelperDisplayed, changeHelperDisplay] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const [currentLoadingMessageIndex, setCurrentLoadingMessageIndex] = useState("status_0");
    const loadingMessages = {
        status_0: { text: "loading" },
        status_1: { text: t('bot_loading_message_1') },
        status_2: { text: t('bot_loading_message_2') },
        status_3: { text: t('bot_loading_message_3') },
        status_4: { text: t('bot_loading_message_4') },
        status_5: { text: t('bot_loading_message_5') },
        status_6: { text: t('bot_loading_message_6') },
        error_1: { text: t('bot_loading_message_error') },
    };


    useEffect(() => {
        const hideTime = localStorage.getItem(TOOLTIP_STORAGE_KEY);
        if (Date.now() < hideTime) {

            document.querySelector(".tooltip-box").style.display = "none";
            console.log("Tooltip should remain hidden.");
            return;
        }
        document.querySelector(".tooltip-box").style.display = "block";
    }, []);

    useEffect(() => {
        if (isTyping) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [isTyping]);

    useEffect(() => {

        if (messages.length === 1) {
            setMessages([]);
            // Initialize a message from the bot
            const initMessage = {
                text: t("rakhys_greetings"),
                sender: "assistant",
                avatar: aiChat,
            };
            setMessages((prev) => [...prev, initMessage]);
        }

        const buttonLabels = [
            t('helper_question_1'),
            t('helper_question_2'),
            t('helper_question_3'),
            t('helper_question_4'),
            t('helper_question_5'),
            t('helper_question_6'),
            t('helper_question_7'),
            t('helper_question_8'),
            t('helper_question_9'),
            t('helper_question_10'),
        ];
        // Only shuffle and select labels once when the component is mounted
        const randomLabels = buttonLabels
            .sort(() => Math.random() - 0.5)  // Shuffle the array
            .slice(0, 4);  // Get the first 4 items from the shuffled array

        setRandomHelperTextLabels(randomLabels);  // Set the random labels in state
    }, [i18n.language]);

    useEffect(() => {

        if (textareaRef.current) {
            textareaRef.current.focus();
        }
        messagesEndRef.current?.parentElement?.scrollBy({ top: 150, behavior: "smooth" });
    }, [messages]);

    const ClearChatSession = () => {

        if (textareaRef.current) {
            textareaRef.current.focus();
        }

        if (messages.length > 0) {
            setMessages([]);
            sessionStorage.removeItem("sessionId");
            const newId = uuidv4();
            sessionStorage.setItem("sessionId", newId);
            setSessionId(newId);
            changeHelperDisplay(true);
        }

        const initMessage = {
            text: t("rakhys_greetings"),
            sender: "assistant",
            avatar: aiChat,
        };
        setMessages((prev) => [...prev, initMessage]);
    };

    const handleButtonClick = async () => {

        document.querySelector(".tooltip-box").style.display = "none";
        setClicked(!clicked);
        if (!clicked) {
            if (messages.length === 0) {
                setMessages([]);
                // Initialize a message from the bot
                const initMessage = {
                    text: t("rakhys_greetings"),
                    sender: "assistant",
                    avatar: aiChat,
                };
                setMessages((prev) => [...prev, initMessage]);

                sessionStorage.removeItem("sessionId");
                const newId = uuidv4();
                sessionStorage.setItem("sessionId", newId);
                setSessionId(newId);
            } else {
                setMessages([...messages]);
            }
        } else {
            if (messages.length <= 1) {
                setMessages([]);
            } else {
                setMessages([...messages]);
            }
        }
    };

    const handleSendMessage = async () => {

        if (inputMessage.trim()) {
            const newMessage = {
                text: inputMessage,
                sender: "user",
                avatar: user,
            };
            setMessages((prev) => [...prev, newMessage]);
            setInputMessage("");
            const textarea = textareaRef.current;

            if (textarea) {
                textarea.style.height = "auto"; // Reset the height to recalculate
            }

            setCurrentLoadingMessageIndex("status_0");
            setIsTyping(true);
            changeHelperDisplay(false);

            try {
                await sendMessage(inputMessage, sessionId, (response) => {
                    if (response.status === "success") {
                        setIsTyping(false);
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                text: response.response,
                                end_text: response.end_text,
                                query_lang: response.query_lang,
                                products: Array.isArray(response.products)
                                    ? response.products
                                    : JSON.parse(response.products),
                                sender: "assistant",
                                avatar: aiChat,
                            },
                        ]);
                    }
                    else if (response.status.startsWith("error")) {
                        setIsTyping(false); // Stop typing animation
                        setCurrentLoadingMessageIndex("error_1"); // Stop showing loading message

                        // Display error message from the assistant
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                text: loadingMessages["error_1"].text,
                                end_text: "",
                                products: [],
                                sender: "assistant",
                                avatar: aiChat,
                            },
                        ]);
                    }
                    else {
                        setCurrentLoadingMessageIndex(response.status);
                    }
                });
                // const response = await sendMessage(inputMessage, sessionId);

            } catch (error) {
                setIsTyping(false);
                console.error("Failed to send message", error.message);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        text: "Sorry, I couldn't process your request. Please try again later.",
                        end_text: "",
                        products: [],
                        sender: "assistant",
                        avatar: aiChat,
                    },
                ]);
                return error; // Throw error for handling in the component
            }
        }


    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputMessage(value);

        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = "auto"; // Reset the height to recalculate

            const maxHeight = 80; // Maximum height for 3 rows

            if (textarea.scrollHeight <= maxHeight) {
                textarea.style.overflowY = "hidden";
                textarea.style.height = `${textarea.scrollHeight}px`;
            } else {
                textarea.style.overflowY = "auto";
                textarea.style.height = `${maxHeight}px`;
            }
        }

        // Handle RTL for Arabic text
        const isArabic = /[\u0600-\u06FF]/.test(value);
        e.target.style.direction = isArabic ? "rtl" : "ltr";
    };

    const closeTooltip = () => {
        const tooltipBox = document.querySelector('.tooltip-box');
        if (tooltipBox) {
            tooltipBox.style.display = 'none';
            localStorage.setItem(TOOLTIP_STORAGE_KEY, TOOLTIP_DISPLAY_DURATION);
        }
    };

    const handleHelperTextButton = (label) => {
        setInputMessage(label);
    };

    const isRTL = (text) => {
        const rtlChar = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
        return rtlChar.test(text);
    };

    return (

        <div className={`tooltip-container ${language} font-lato`}>

            <div className="tooltip-box">
                <span className={`tooltip-close ${language}`} onClick={closeTooltip}>&#10005;</span>
                <div className="tooltip-header">
                    <span>{t("rakhys_ai")}</span>

                </div>
                <div className="tooltip-text">{t("tooltip")}</div>
                <div className={`tooltip-arrow ${language}`}></div>
            </div>


            <div style={{ position: "relative", display: "block" }}>
                <button
                    className={`floating-button ${language} ${clicked ? "clicked" : ""}`}
                    onClick={handleButtonClick}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <img
                        src={aiChat}
                        alt="Rakhys Icon"
                        style={{ width: "48px", height: "48px" }}
                    />
                </button>

                {clicked && (
                    <div className={`chat-window ${language}`}>
                        <div className="chat-header">
                            <div className="flex items-center justify-between w-full">
                                <img
                                    src={close}
                                    alt="Close button"
                                    title="Close"
                                    onClick={handleButtonClick}
                                    style={{
                                        cursor: "pointer",
                                        width: "16px",
                                        height: "16px",
                                    }}
                                />
                                <img
                                    className="logo"
                                    src={language === 'ar' ? aiLogoAr : aiLogoEn}
                                    alt="Rakhys logo"
                                    style={{ width: "224px", height: "42px" }}
                                />

                                <img
                                    src={clear}
                                    alt="clear button"
                                    title="Clear"
                                    onClick={ClearChatSession}
                                    style={{
                                        cursor: "pointer",
                                        width: "30px",
                                        height: "30px",
                                    }}
                                />
                            </div>

                        </div>

                        <div className="chat-messages">
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`message ${message.sender} `}
                                >
                                    <img
                                        src={message.avatar}
                                        alt="Avatar"
                                    />
                                    <div
                                        className={`message-content ${message.sender} ${language} ${isRTL(message.text) ? "rtl" : ""
                                            }`}
                                    >
                                        {!message.products ||
                                            message.products.length === 0 ? (
                                            <ReactMarkdown>
                                                {message.text}
                                            </ReactMarkdown>
                                        ) : (
                                            <>
                                                <ReactMarkdown>
                                                    {message.text}
                                                </ReactMarkdown>
                                                {message.products.map(
                                                    (product, index) => (
                                                        <AIProductCard
                                                            key={index}
                                                            url={`/product/${createSlug(product.name_en)}-${product.id}-${product.product_id}`}
                                                            image={
                                                                product?.photo_url ||
                                                                "../src/assets/picture-loading.svg"
                                                            }
                                                            title={
                                                                message.query_lang == "Arabic" && !product?.name_ar ?
                                                                    product?.name_en :
                                                                    (message.query_lang == "Arabic" ? product?.name_ar : product?.name_en) || ""
                                                            }
                                                            price={
                                                                product?.price || ""
                                                            }
                                                            oldPrice={
                                                                product?.was_price ===
                                                                    product?.price
                                                                    ? ""
                                                                    : product?.was_price ||
                                                                    ""
                                                            }
                                                            discount={
                                                                product?.discount ||
                                                                ""
                                                            }
                                                            productStoreUrl={
                                                                (product?.url_en || product?.url_ar) + "?ref=rakhys"
                                                            }
                                                            query_lang={message.query_lang}
                                                            currency_ar={
                                                                product?.currency_ar
                                                            }
                                                            currency_en={
                                                                product?.currency_en
                                                            }
                                                            store_name_en={product?.store_name_en}
                                                            store_name=
                                                            {
                                                                message.query_lang == "Arabic" ?
                                                                    product?.store_name_ar : product?.store_name_en

                                                            }
                                                        />
                                                    ),
                                                )}

                                                <ReactMarkdown>
                                                    {message.end_text}
                                                </ReactMarkdown>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {/* Typing indicator */}
                            {isTyping && (

                                <div className="typing-indicator">
                                    <img
                                        src={aiChat}
                                        alt="Bot"
                                        className="bot-icon"
                                    />
                                    {currentLoadingMessageIndex === "status_0" ? (
                                        <div className="typing-dots">
                                            <div className="typing-dot"></div>
                                            <div className="typing-dot"></div>
                                            <div className="typing-dot"></div>
                                        </div>
                                    ) : (
                                        <div className="text-wrapper">
                                            <div className="loading-text typing-text">
                                                {loadingMessages[currentLoadingMessageIndex].text}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>

                        <div
                            className="rounded-3xs flex flex-row items-start justify-start self-stretch overflow-hidden px-5 py-[5px]"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "10px 20px",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {isHelperDisplayed && (
                                    <div
                                        className="chat-helper-container"
                                    >
                                        {randomHelperTextLabels.map((label, index) => (
                                            <div key={index} className="chat-helper-card"
                                            >
                                                <button
                                                    className="chat-helper-dynamicButton"
                                                    onClick={() => handleHelperTextButton(label)}
                                                >
                                                    {label}
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}


                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <textarea
                                        ref={textareaRef}
                                        className={`chat-input ${language}`}
                                        placeholder={t("ask_rakhys")}
                                        value={inputMessage}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleInputChange}
                                        disabled={isTyping}
                                        rows={1}
                                        style={{
                                            margin: "0 0 4px 0",
                                            resize: "none",
                                            overflowX: "hidden",
                                            overflowY: "auto",
                                            minHeight: "32px",
                                            maxHeight: "80px",
                                            borderRadius: "2px",
                                            padding: "4px 4px",
                                            boxSizing: "border-box",
                                            outline: "none",
                                            fontFamily: "Cairo"
                                        }}
                                        wrap="soft"
                                    />
                                    <button
                                        className={`chat-input-button ${language}`}
                                        onClick={handleSendMessage}
                                        disabled={isTyping}
                                    >
                                        <img
                                            src={isTyping ? stopIcon : sendIcon} // Change icon based on state
                                            alt={isTyping ? "Stop Icon" : "Send Icon"}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 text-center text-[10px] text-gray-400  ">
                            {t("ai_disclaimer")}
                        </div>

                        <div className="pb-1 px-2 text-center text-[10px] text-gray-400">
                            {t("made_by")}<a className="underline" href="https://www.nexumind.com">NexuMind</a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FloatingChatButton;
